.custom-card-templates{
    border-radius: 12px !important; 
    transition: transform 0.2s ease-in-out;
}

  
  .custom-card-templates:hover {
    transform: translateY(-5px);
  }
  
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  

.custom-card-chatbot {
  border-radius: 12px !important;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.custom-card-chatbot:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.custom-card-chatbot .ant-card-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-content {
  width: 100% !important;
  margin-bottom: 16px;
}

.card-title {
  margin: 0 0 12px 0 !important;
  text-align: center;
  word-break: break-word;
  font-size: 16px !important;
}

.description-container {
  width: 100%;
  overflow: hidden;
}

.description-text {
  word-break: break-word;
  overflow: hidden;
  margin: 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 8px;
  line-height: 1.5;
}

.chat-button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  margin-top: 8px;
}

.status-row {
  padding: 0 8px;
}

.button-section {
  padding: 16px 0;
}

.footer-section {
  margin-top: auto;
}


.chat-button[disabled] {
  background-color: #BAD7FF !important;
  color: white !important;
  border-color: #BAD7FF !important;
  cursor: not-allowed;
}

.chat-button[disabled]:hover {
  background-color: #BAD7FF !important;
  color: white !important;
  border-color: #BAD7FF !important;
}

@media (max-width: 576px) {
  .custom-card-chatbot .ant-card-body {
    padding: 12px;
  }
  
  .card-title {
    font-size: 14px !important;
  }

  .description-section {
    margin-top: 16px;
    margin-bottom: 16px;
    min-height: 80px;
  }
}