/*-------------------------------------BARRA LATERAL-----------------------*/
.ant-layout-sider {
  /* border-top-right-radius: 50px !important; */
}
.ant-layout-sider .ant-menu-item-active {
  background-color: rgba(34, 242, 255, 0.3) !important;
  border-radius: 24px;
}
.ant-layout-sider .ant-menu-item-selected {
  background-color: rgba(34, 242, 255, 0.5) !important;
  border-radius: 24px;
  color: #0c5257;
}
.ant-layout-sider-children div:nth-child(3) span:nth-child(3) {
  font-weight: 500 !important;
}
/*-----------------------------HEADER----------------------------------------*/
.ant-layout-header {
  background-color: white !important;
}

/*PAGINA DE INICIO-----------------------------------------------------------------------------------------------------------------------*/
/*-------------------------TEXTO--------------------------*/
.text-hola {
  font-size: 40px;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: 5px;
}

/*--------------------------MENSAJES----------------------*/
.cardTokens .ant-card {
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
  height: 130px !important;
  padding-left: 20px !important;
}
.ant-card-body .ant-typography {
  border-radius: 24px !important;
}
/*---------------------------GRAFICO----------------------*/
.graficoDonut .recharts-legend-item-text,
.graficoDonut svg .recharts-layer text {
  fill: #0c5257 !important;
  font-weight: 500 !important;
}
.graficoDonut .recharts-default-legend {
  padding-left: 40px !important;
}

/*--------------------------BOTONES----------------------*/
.btn-container .ant-btn {
  padding: 24px 48px;
  border-radius: 24px;
  background: #0c5257;
  color: white;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  z-index: 1;
  border: #0c5257 2px solid;
}
.btn-container .ant-btn:hover {
  background: linear-gradient(
    137deg,
    rgba(34, 242, 255, 1) 0%,
    rgba(0, 255, 194, 1) 100%
  ) !important;
  transition: top 0.6s ease !important;
  color: #0c5257 !important;
  border: #0c5257 2px solid;
}

/*--------------------------CARDBOTS--------------------*/

.cardbots {
  background-color: #fff;
  padding: 40px;
  border-radius: 50px;
  box-shadow: 0 0px 2px rgba(12, 12, 12, 0.2);
}
.custom-card {
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  background-color: rgba(34, 242, 255, 0.3);
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
}
.custom-card:hover {
  box-shadow: 0 4px 12px rgba(34, 242, 255, 0.5);
  border: 1px solid #fff;
}
.custom-card-select {
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  border: 2px solid rgba(34, 242, 255, 1);
  background: linear-gradient(
    137deg,
    rgba(34, 242, 255, 1) 0%,
    rgba(0, 255, 194, 1) 100%
  );
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
}
.ant-card-body div div span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.ant-card-actions li {
  margin: 8px 0px 12px 0 !important;
}
.ant-card-actions button span {
  font-weight: 500;
  color: #0c5257;
}
.ant-card-actions button span:hover {
  color: rgba(34, 242, 255, 1);
}

.card-description {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

/*---------------------------------------------------TABS-----------------------------------------------------------------------------------------*/
.ant-tabs-nav-wrap .ant-tabs-nav-list {
  justify-content: center;
  transform: translate(2px, 0px) !important;
}

.ant-tabs-nav-wrap .ant-tabs-tab {
  padding: 15px 25px !important;
  color: #0c5257 !important;
  border-radius: 30px;
  border: none !important;
}
.ant-tabs-nav-wrap .ant-tabs-tab-active {
  background-color: rgba(34, 242, 255, 0.5);
  /* border: 2px #0c5257 solid !important; */
}
.ant-tabs-nav-wrap .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c5257 !important;
}
.ant-tabs-nav-wrap .ant-tabs-tab:hover {
  background-color: rgba(34, 242, 255, 0.3) !important;
}

.ant-tabs-content-holder .ant-steps-item-active .ant-steps-item-icon {
  background-color: rgba(34, 242, 255, 1) !important;
  border: 1px solid #0c5257 !important;
}
.ant-tabs-content-holder .ant-steps-item-icon {
  background-color: rgba(34, 242, 255, 0.3) !important;
  border: 1px solid #0c525744 !important;
  color: #0c525744 !important;
}
.ant-tabs-content-holder .ant-steps-item-active .ant-steps-icon {
  color: #0c5257 !important;
}
.ant-tabs-content-holder .ant-steps-item-title::after {
  background-color: rgba(34, 242, 255, 1) !important;
}
.ant-card {
  border-radius: 50px !important;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
}
.ant-tabs-content-holder .ant-card {
  border-radius: 50px !important;
  padding: 20px;
}
.ant-tabs-content-holder .ant-steps-item-title {
  font-weight: 500;
}
.ant-card-body .ant-picker:hover {
  border: 1px solid rgba(34, 242, 255, 1);
}
.ant-tabs-content-holder .ant-input:hover {
  border: rgba(34, 242, 255, 1) 1px solid;
}
.ant-tabs-content-holder button:nth-child(1) {
  padding: 24px 48px;
  border-radius: 24px !important;
  background: #0c5257 !important;
  color: white;
  border: 2px solid #0c5257 !important;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.ant-tabs-content-holder button:nth-child(2) {
  padding: 22px 46px;
  border-radius: 24px;
  background: transparent;
  color: #0c5257;
  border: 2px solid #0c5257;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.ant-tabs-content-holder button:hover {
  background: linear-gradient(
    137deg,
    rgba(34, 242, 255, 1) 0%,
    rgba(0, 255, 194, 1) 100%
  ) !important;
  border: 2px solid #0c5257 !important;
  transition: top 0.6s ease !important;
  color: #0c5257 !important;
}
.ant-space-item .ant-btn-icon-only {
  padding: 20px !important;
}
.ant-space-item button {
  color: #fff !important;
}
.ant-space-item button:hover {
  color: #0c5257 !important;
}
.ant-space-item .ant-btn-icon-only svg {
  color: #fff;
}
.ant-space-item .ant-btn-icon-only svg:hover {
  color: #0c5257;
}
.ant-upload-select .ant-upload button {
  color: #0c5257 !important;
}
/*-----------------------------------CHAT--------------------------------------*/
.contenedor-chat {
  border-radius: 25px !important;
}

/*----------------------------------GRAFICO------------------------------------*/
.Table-Container-Actions-Global {
  margin: 30px 0 0 30px;
}
.ant-table-wrapper {
  margin-top: 20px !important;
}
.ant-pagination-item-link {
  color: #fff !important;
  border-radius: 20px !important;
  background-color: #0c5257 !important;
  border: 2px solid #0c5257 !important;
}
.ant-pagination-item-active {
  border: #0c5257 2px solid !important;
  border-radius: 20px !important;
  color: #0c5257 !important;
}
.ant-pagination-item:hover {
  border-radius: 20px;
  border: #0c5257 1px solid !important;
}
.ant-tabs-content .ant-spin-container {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-top: 30px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
}
.ant-spin-container .ant-table-pagination-right {
  margin-right: 30px !important;
}
.ant-spin-container .ant-table {
  margin: 20px;
  padding: 0px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(12, 12, 12, 0.2);
}
.ant-table .ant-table-thead th {
  padding-left: 30px !important;
}
.ant-table .ant-table-tbody .ant-table-row td:nth-child(1) {
  padding-left: 35px !important;
  padding-right: 10px !important;
}
.ant-spin-container .ant-progress-bg,
.ant-spin-container .ant-progress-bg-outer {
  background: linear-gradient(
    90deg,
    rgba(34, 242, 255, 1) 0%,
    rgba(0, 255, 194, 1) 70%
  ) !important;
  --progress-line-stroke-color: linear-gradient(
    to right,
    rgba(34, 242, 255, 1),
    rgba(0, 255, 194, 1)
  ) !important;
}
.recharts-bar:nth-child(even) path {
  fill: rgba(34, 242, 255, 0.8) !important;
}
.recharts-bar path {
  fill: rgba(0, 255, 194, 0.8);
}
.recharts-bar path:hover {
  border: #0c5257 2px solid !important;
}
.recharts-default-legend .recharts-surface path {
  fill: rgba(0, 255, 194, 0.8);
}
.recharts-default-legend .recharts-legend-item-text {
  color: #0c5257 !important;
}
.recharts-default-legend .legend-item-1 path {
  fill: rgba(34, 242, 255, 0.8) !important;
}

/*--------------------USUARIOS-----------------*/
.boton-crear {
  padding: 24px 48px;
  border-radius: 24px;
  background: #0c5257;
  color: white;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  z-index: 1;
  margin-left: 24px;
  border: #0c5257 2px solid;
}
.boton-crear:hover {
  background: linear-gradient(
    137deg,
    rgba(34, 242, 255, 1) 0%,
    rgba(0, 255, 194, 1) 100%
  ) !important;
  transition: top 0.6s ease !important;
  color: #0c5257 !important;
  border: #0c5257 2px solid;
}
.ant-table table tr:nth-child(even) {
  background: rgba(34, 242, 255, 0.1) !important;
}
.ant-table table tr {
  background: rgba(0, 255, 194, 0.2) !important;
}
.ant-table table tbody tr .ant-table-selection-column {
  padding-left: 35px !important;
}
