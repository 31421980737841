.container {
  height: 100vh;
  display: flex;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(137deg, rgba(0, 255, 194, 1) 0%, rgba(34, 242, 255, 1) 100%);
  width: 50%;
  color: white;
}
.left h1{
  font-size: 60px;
  color: #0C5257;
}
.left p, .left h3{
  font-size: 28px;
  margin-bottom: 0px;
  color: #0C5257;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 48px 160px;
  align-items: center;
  justify-content: center;
}
.right h1{
  font-size: 34px;
  color: #0C5257 !important;
}
.right a{
  font-size: 18px;
  color: #0C5257 !important;
}
.right a:hover{
  background: linear-gradient(137deg, rgba(34, 242, 255, 1) 0%, rgba(0, 255, 194, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  transition: 1s ease-in-out;
}

.input_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  outline: none; 
  transition: border-color 0.4s ease; 
}
.input_box input:hover, .input_box input:active{
  border: rgba(34, 242, 255, 1) 1px solid;
}
.btn_box {
  width: 100%;
  margin-top: 40px;
}
.login_btn {
  width: 100%;
  background-color: #0C5257;
  border-radius: 10px;
  border: none;
  height: 50px;
  color: white;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.4s ease;
  z-index: 1;
}
.login_btn::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, rgba(34, 242, 255, 1) 0%, rgba(0, 255, 194, 1) 100%);
  transition: top 0.4s ease;
  z-index: -1; /* Asegura que el gradiente esté detrás del contenido */
}

.login_btn:hover::after {
  top: 0;
}
.login_btn:hover {
  color: #0C5257; /* Cambia el color del texto al hacer hover */
}
.login_btn::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, rgba(34, 242, 255, 1) 0%, rgba(0, 255, 194, 1) 100%);
  transition: top 0.4s ease;
  z-index: -1; /* Asegura que el gradiente esté detrás del contenido */
}

.login_btn:hover::after {
  top: 0;
}
.login_btn:hover {
  color: #0C5257; /* Cambia el color del texto al hacer hover */
}