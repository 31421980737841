.container-image-login{
  background-color: #f0f2f5;
  background-size: cover;
  background-position: center;
}

.button-google{
  width:222px; 
  height: 44px !important; 
  background-color:#FFFFFF;
  border-radius:12px;
  border: 1px solid #E1E1E2;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.button-google:hover{
  border: 2px solid #016AA6 !important;
  height: 42px !important; 
}

.input-login-plantilla{
  height: 58px;
  font-size:18px;
  border-radius:4px;
  font-weight: 500;
}

.input-login-plantilla input{
  font-size:18px;
  font-weight: 500;
}

.input-login-plantilla:hover{
  background-color: #f4f4f5;
  border: 2px solid #E1E1E2;
}
.input-login-plantilla:focus{
  border: 4px solid #91C3FD;
  color: #5f5f5f;
}

.ant-input-password.input-login-plantilla:focus{
  color: #5f5f5f;
}