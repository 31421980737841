/* Table header styles */
.custom-table .ant-table-thead > tr > th {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  padding-left: 8px !important;
}

/* Table body styles */
.custom-table .ant-table-tbody > tr > td {
  font-size: 11px;
  text-align: center;
}

/* Table header styles */
.custom-table2 .ant-table-thead > tr > th {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  padding-left: 8px !important;
}

/* Table body styles */
.custom-table2 .ant-table-tbody > tr > td {
  font-size: 12px;
  text-align: center;
  padding: 8px;
  padding-left: 8px !important;
}

/* Ellipsis styles for cells */
.cell-ellipsis {
  max-width: 150px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Tooltip styles to ensure full text is visible on hover */
.ant-tooltip-inner {
  max-width: none;
  word-wrap: break-word;
  white-space: normal;
}

.custom-container {
  background-color: #ffffff; /* Fondo blanco */
  border-radius: 50px; /* Bordes redondeados */
  box-shadow: 0 0px 2px rgba(12, 12, 12, 0.2); /* Sombra suave */
  padding: 20px; /* Espaciado interno */
  margin: 5px auto;
  margin-top: 24px;
}

.responsive-table-container {
  max-width: 80%;
  margin: 0 auto;
  overflow-x: auto;
}

@media (max-width: 1300px) {
  .responsive-table-container {
    max-width: 100%; /* Ancho completo en pantallas pequeñas */
  }
}

