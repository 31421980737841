.notification-container {
  position: absolute;
  /* cursor: grab; */
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  position: relative;
  border: blue 1px solid;
}

.notification-dot {
  width: 13px;
  height: 13px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -0px;
  left: -0px;
}

.notification-message-dos {
  padding: 5px 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  position: absolute;
  /* opacity: 0; */
  animation: fadeInOut 4s forwards;
  width: 100%;
}

.notification-message-dos.left {
  position: absolute;
  top: 0px;
  right: 100%;
  width: 100px;
  margin-right: 10px;
}

.notification-message-dos.left::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -9px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #03a9f4;
}

.notification-message-dos.right {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  margin-left: 10px;
}

.notification-message-dos.right::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -9px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #f8d7da transparent transparent;
}

.tooltip-container {
  position: absolute;
  /* top: -470px;
  right: 20px; */
  width: 400px;
  opacity: 0;
  display: none;
  transform: scale(1);
  padding-right: 20px;
  border-radius: 8px;
  z-index: 100;
  transition: transform 0.9s ease;
  /* background-color: white; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
}

.tooltip-container-embedded {
  position: absolute;
  display: none;
  top: 0px;
  width: 400px;
  opacity: 0;
  transform: scale(1);
  padding: 20px;
  border-radius: 8px;
  z-index: 100;
  transition: transform 0.9s ease;
  /* right: 20px; */
  /* background-color: white; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
  /* top: -470px; */
}

.tooltip-container div {
  background-color: white;
}
.tooltip-container.expanded {
  opacity: 1;
  display: block;
  transform: scale(1);
  bottom: 0;
  right: 50px;
}
.tooltip-triangle {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #4b4b4b;
}

@media (max-width: 768px) {
  .tooltip-body {
    padding: 10px;
    width: 100%;
    max-width: none;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
